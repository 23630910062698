<template>
  <div class="about">
    <!-- 浏览记录车辆 -->
    <!-- <div @click="countadd(10)" v-for="(item,index) in this.$store.state.count" :key="index">{{item}}</div> -->
    <!-- <div class="container">
      <div class="card-list">
        <div
          class="card-item"
          v-for="(item, index) in this.$store.state.recordCars"
          :key="index"
        >
          <div
            v-if="item.car_pic"
            class="pic"
            :style="[
                {
                  backgroundImage: 'url(' + baseurl + item.car_pic + ')',
                },
              ]"
          ></div>
          <el-tooltip class="item" effect="dark" :content="item.car_name" placement="top-start">
            <p class="card-name">{{ item.car_name }}</p>
          </el-tooltip>
          <p class="card-time">{{ item.car_age }}/{{ item.travel_km }}</p>
          <p class="price">
            <span class="proce-unit">￥</span>
            {{ item.sale_price }}万
            <span class="old-price">¥{{ item.price }}万</span>
          </p>
        </div>
      </div>
    </div> -->
    <!-- 背景图 -->
    <div class="img-back">
      <img src alt />
    </div>
    <div class="content">
      <div class="about-box" v-html="aboutcontent">
        <!-- <div class="about-list" v-for="(item,index) in about" :key="index">
          <div class="img-box">
            <img src alt />
          </div>
          <div class="title">{{item.title}}</div>
          <div class="info">{{item.info}}</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { aboutus } from "@/api/index.js";
export default {
  data() {
    return {
      aboutcontent: "",
      about: [
        {
          imgurl: "",
          title: "品牌简介",
          info:
            "悦心车专注于高品质豪华二手车零售及服务。搭载深耕十余年的豪华品牌车系技术检测与整备升级能力，致力于让每一位车主用二手车的价格，买到新车的品质与服务。",
        },
        {
          imgurl: "",
          title: "品牌优势",
          info:
            "悦心车作为北京华奥汽车服务股份有限公司的子品牌，服务网络覆盖全国90余座城市。强大的汽车检测及整备能力、10余项汽车检测专利、上千名专业技术人员、成熟的管理体系网络、先进的品牌服务理念等，无一不在保障质量与服务的高标准。用户可通过悦心车官方网站及微信小程序在线看车，也可通过前往悦心车线下体验店实地看车。",
        },
        {
          imgurl: "",
          title: "品牌承诺",
          info:
            "悦心车承诺，所有上架车辆均经过严格的检测及必要的整备环节，车况完好如新。并将服务环节深入到用户的售后体验中，让每一位用户都能买车放心、用车安心、养车省心。",
        },
      ],
    };
  },
  mounted() {
    this.aboutus();
  },
  methods: {
    // countadd(ll) {
    //   this.$store.commit("addcount",ll)
    // },
    // 关于我们富文本
    aboutus() {
      aboutus({}).then((res) => {
        this.aboutcontent = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.about {
  width: 100%;
  background-color: #fff;
  padding-bottom: 50px;
  box-sizing: border-box;

  .container {
    width: 100%;
    margin-top: 102px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        display: inline-block;
        width: 93px;
        height: 1px;
        background: #000000;
        opacity: 0.1;
      }
      .text {
        color: #0097e2;
        font-size: 30px;
        font-weight: 500;
        margin: 0 19px;
      }
    }
    .card-list {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .card-item {
        width: 286px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(244, 244, 244, 1);
        margin-right: 17px;
        margin-top: 33px;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .pic {
          width: 100%;
          height: 203px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .card-name {
          font-size: 16px;
          font-weight: 300;
          color: #000000;
          margin-left: 10px;
          margin-top: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .card-time {
          font-size: 16px;
          font-weight: 300;
          color: #0097e2;
          margin-left: 10px;
          margin-top: 4px;
          margin-bottom: 13px;
        }
        .price {
          font-size: 18px;
          padding-left: 10px;
          color: #fff;
          background: #0097e2;
          font-weight: bold;
          .proce-unit {
            font-size: 14px;
            color: #fff;
            font-weight: bold;
          }
          .old-price {
            font-size: 16px;
            font-weight: 300;
            text-decoration: line-through;
            color: #fff;
            margin-left: 12px;
          }
        }
      }
    }
    .see-more {
      width: 190px;
      height: 42px;
      background: rgba(0, 151, 226, 1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      font-weight: 400;
      color: #fff;
      margin: 58px auto 127px;
      cursor: pointer;
    }
  }

  .img-back {
    width: 100%;
    min-width: 1200px;
    height: 400px;
    padding-top: 60px;
    box-sizing: border-box;
    background-image: url("https://www.yuexinche.com/pic/pc6.jpg");
    background-size: cover;
    background-position: center;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // img{
    //   max-width: 100%;
    //   max-height: 100%;
    // }
  }

  .content {
    width: 1200px;
    min-width: 1200px;
    height: auto;
    background-color: #fbfbfb;
    position: relative;
    top: -60px;
    margin: 0 auto;
    padding: 100px 65px 80px;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .about-box {
      width: 100%;
      display: flex;
      p {
        width: 100%;

        img {
          width: 100%;
        }
      }
      /deep/img {
        width: 100%;
      }
      .about-list {
        width: 32%;
        margin-right: 2%;
        background-color: #f5f3f2;
        .img-box {
          width: 100%;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: red;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .title {
          padding: 0 35px;
          box-sizing: border-box;
          font-size: 21px;
          color: #000;
          font-weight: bold;
          margin-top: 45px;
        }
        .info {
          padding: 0 35px 50px;
          box-sizing: border-box;
          font-size: 14px;
          color: #000;
          line-height: 30px;
          margin-top: 10px;
        }
        &:last-child {
          margin-right: 2%;
        }
      }
    }
  }
}
</style>
